import { useState, useEffect, useMemo } from "react";
import { getApi } from "../../utils/api";
import { HIRING_URL } from "../../helpers/constants";
import { RouteConstants } from "../../helpers/RouteConstants";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Typography } from "@material-tailwind/react";
import { FeaturesData } from "./payload.model";
import moment from "moment";
import { Box } from "@mui/material";
import CreateBusFeature from "./createVehicleTypes";

const VehicleTypesPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [allFeatures, setAllFeatures] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [reFetch, setReFetch] = useState(false);

  // open create modal
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = () => {
    setIsCreateOpen(true);
  };
  const handleCreateClose = () => {
    setIsCreateOpen(false);
  };

  //on page load
  useEffect(() => {
    const fetchHiringRequests = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        const response = await getApi(
          `${HIRING_URL}${RouteConstants.VEHICLE_TYPES}?sorting=created_at:desc&limit=${pagination.pageSize}&offset=${offset}`
        );
        setAllFeatures(response?.data?.payload?.items || []);
        setTotal(response?.data?.payload?.total);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHiringRequests();
  }, [pagination.pageIndex, pagination.pageSize, reFetch]);

  // table columns
  const columns = useMemo<MRT_ColumnDef<FeaturesData>[]>(
    () => [
      {
        accessorFn: (row: FeaturesData) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: FeaturesData) => `${row?.code}`,
        id: "code",
        header: "Code",
      },
      {
        accessorFn: (row: FeaturesData) => `${row?.created_at}`,
        id: "created",
        header: "Date Created",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("DD MMM YYYY - h:mma")}</Box>
        ),
      },
      {
        accessorFn: (row: FeaturesData) => `${row?.creator?.name}`,
        id: "creator",
        header: "Created By",
      },
      {
        accessorFn: (row: FeaturesData) => `${row?.updated_at}`,
        id: "updated",
        header: "Date Updated",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("DD MMM YYYY - h:mma")}</Box>
        ),
      },
      {
        accessorFn: (row: FeaturesData) => `${row?.updater?.name}`,
        id: "updater",
        header: "Updated By",
      },
    ],

    // eslint-disable-next-line
    []
  );

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar vehicleTypesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            {/* page content */}
            <div className="p-[10px] lg:p-[20px] h-full overflow-y-auto bg-[#7c000008]">
              <div>
                <Typography className="font-semibold text-xl">
                  Vehicle Types
                </Typography>
              </div>
              <div className="py-3 px-5 mt-3 rounded-[10px] bg-white shadow-md w-full flex justify-end items-center">
                <div>
                  <button
                    className="px-3 py-1 bg-oya-ghana-red text-white rounded-[5px]"
                    onClick={() => handleCreateOpen()}
                  >
                    + Create New
                  </button>
                </div>
              </div>

              <div className="mt-4 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={allFeatures}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{ pagination, isLoading: isFetching }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* open create modal */}
      {isCreateOpen ? (
        <CreateBusFeature
          isOpened={isCreateOpen}
          handleClose={handleCreateClose}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VehicleTypesPage;
