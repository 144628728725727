import { DatePicker } from "antd";
import moment from "moment";

interface dateProps {
    onDateChange: (date: any) => void
    placeholder: string
    disabled?: boolean
}

const DateTimeInput = ({ onDateChange, placeholder, disabled }: dateProps) => {

    // function to disable past dates
    const disabledDate = (current: any | null) => {
        return current ? current < moment().startOf("day") : false;
    };

    return (
        <div>
            <DatePicker showTime={{ format: 'HH:mm a', showNow: true }} onChange={onDateChange} placeholder={placeholder} disabled={disabled} disabledDate={disabledDate} />
        </div>
    );
}

export default DateTimeInput;
