import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  CheckBadgeIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  Squares2X2Icon,
  StarIcon,
  TagIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";
import logoImg from "../../images/logo.png";
import { Typography } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import {
  LiaGasPumpSolid,
  LiaSitemapSolid,
  LiaCarAltSolid,
} from "react-icons/lia";
import { CategoryOutlined, Speed } from "@mui/icons-material";

interface menuActiveProps {
  dashActive?: string;
  vehiclesActive?: string;
  vehicleBookingsActive?: string;
  featuresActive?: string;
  fuelTypesActive?: string;
  vehicleBrandsActive?: string;
  vehicleModelsActive?: string;
  vehicleTransmissionsActive?: string;
  vehicleTypesActive?: string;
  vehicleStatusesActive?: string;
}

const CustomSideBar = ({
  dashActive,
  vehiclesActive,
  vehicleBookingsActive,
  featuresActive,
  fuelTypesActive,
  vehicleBrandsActive,
  vehicleModelsActive,
  vehicleTransmissionsActive,
  vehicleTypesActive,
  vehicleStatusesActive,
}: menuActiveProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        //mobile
        setIsCollapsed(true);
      } else if (width >= 768 && width < 1024) {
        //tablet
        setIsCollapsed(true);
      } else {
        //desktop
        setIsCollapsed(false);
      }
    };

    handleDeviceType();

    window.addEventListener("resize", handleDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleDeviceType);
    };
  }, []);

  return (
    <Sidebar collapsed={isCollapsed}>
      <Menu className="bg-white h-full">
        {/* logo */}
        <div className="p-3 flex items-center gap-3 w-full">
          <Link className="w-full" to="#">
            <img src={logoImg} alt="logo" />
          </Link>
          <Typography className={`${isCollapsed ? "hidden" : ""}`} variant="h5">
            Marketplace
          </Typography>
          <div
            className={`${
              isCollapsed ? "hidden" : "flex justify-end items-center w-full"
            }`}
          >
            <ChevronDoubleLeftIcon
              className="w-5 h-5 cursor-pointer"
              onClick={() => handleCollapse()}
            />
          </div>
        </div>
        <ChevronDoubleRightIcon
          className={`${
            isCollapsed
              ? "w-5 h-5 cursor-pointer my-3 flex mr-auto ml-auto"
              : "hidden"
          }`}
          onClick={() => handleCollapse()}
        />

        <div className="mt-2">
          <MenuItem
            icon={<Squares2X2Icon className="h-5 w-5 mb-1" />}
            className={dashActive}
            onClick={() => navigate("/")}
          >
            Overview
          </MenuItem>
          <MenuItem
            icon={<LiaCarAltSolid className="h-6 w-6 mb-1" />}
            className={vehiclesActive}
            onClick={() => navigate("/vehicles")}
          >
            Vehicles
          </MenuItem>
          <MenuItem
            icon={<BookmarkIcon className="h-6 w-6 mb-1" />}
            className={vehicleBookingsActive}
            onClick={() => navigate("/vehicle-bookings")}
          >
            Vehicle Bookings
          </MenuItem>
          <MenuItem
            icon={<LiaGasPumpSolid className="h-6 w-6 mb-1" />}
            className={fuelTypesActive}
            onClick={() => navigate("/fuel-types")}
          >
            Fuel Types
          </MenuItem>
          <MenuItem
            icon={<StarIcon className="h-6 w-6 mb-1" />}
            className={vehicleBrandsActive}
            onClick={() => navigate("/vehicle-brands")}
          >
            Vehicle Brands
          </MenuItem>
          <MenuItem
            icon={<TagIcon className="h-6 w-6 mb-1" />}
            className={vehicleModelsActive}
            onClick={() => navigate("/vehicle-models")}
          >
            Vehicle Models
          </MenuItem>
          <MenuItem
            icon={<LiaSitemapSolid className="h-6 w-6 mb-1" />}
            className={featuresActive}
            onClick={() => navigate("/vehicle-features")}
          >
            Vehicle Features
          </MenuItem>

          <MenuItem
            icon={<Speed className="h-6 w-6 mb-1" />}
            className={vehicleTransmissionsActive}
            onClick={() => navigate("/vehicle-transmissions")}
          >
            Vehicle Transmission
          </MenuItem>
          <MenuItem
            icon={<CategoryOutlined className="h-6 w-6 mb-1" />}
            className={vehicleTypesActive}
            onClick={() => navigate("/vehicle-types")}
          >
            Vehicle Types
          </MenuItem>
          <MenuItem
            icon={<CheckBadgeIcon className="h-6 w-6 mb-1" />}
            className={vehicleStatusesActive}
            onClick={() => navigate("/vehicle-statuses")}
          >
            Vehicle Statuses
          </MenuItem>
        </div>
      </Menu>
    </Sidebar>
  );
};

export default CustomSideBar;
