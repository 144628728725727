import { useState } from 'react';
import { Modal, Spin, notification } from 'antd';
import { Spinner } from '@material-tailwind/react';
import LoadingPointSelector from '../../../components/general/loadingPointSelector';
import BusFeaturesSelector from '../../../components/general/busFeaturesSelector';
import DateTimeInput from '../../../components/general/dateTimeInput';
import { postApi } from '../../../utils/api';
import { HIRING_URL } from '../../../helpers/constants';
import { RouteConstants } from '../../../helpers/RouteConstants';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const CreateHiringModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [activeTripType, setActiveTripType] = useState("One Way Trip")
    const [minPassenger, setMinPassenger] = useState("")
    const [maxPassenger, setMaxPassenger] = useState("")
    const [sourceLocation, setSourceLocation] = useState({
        city: "",
        placeName: "",
        region: "",
        latitude: 0,
        longitude: 0,
    });
    const [destinationLocation, setDestinationLocation] = useState({
        city: "",
        placeName: "",
        region: "",
        latitude: 0,
        longitude: 0,
    });
    const [theDescription, setTheDescription] = useState("")
    const [startingDate, setStartingDate] = useState("")
    const [endingDate, setEndingDate] = useState("")
    const [selectedFeatures, setSelectedFeatures] = useState([])

    //handleSourceSelect
    const handleSourceSelect = (data: any) => {
        setSourceLocation({
            city: data?.value?.city_name,
            placeName: data?.value?.place_name,
            region: data?.value?.region,
            latitude: data?.value?.latitude,
            longitude: data?.value?.longitude
        })
    }
    //handleDestinationSelect
    const handleDestinationSelect = (data: any) => {
        setDestinationLocation({
            city: data?.value?.city_name,
            placeName: data?.value?.place_name,
            region: data?.value?.region,
            latitude: data?.value?.latitude,
            longitude: data?.value?.longitude
        })
    }

    //handleStartDateChange
    const handleStartDateChange = (date: any) => {
        if (date) {
            const convertToISO = date.toISOString();
            setStartingDate(convertToISO);
        }
    };
    //handleEndDateChange
    const handleEndDateChange = (date: any) => {
        if (date) {
            const convertToISO = date.toISOString();
            setEndingDate(convertToISO);
        }
    };

    //handleCreateHiring
    const handleCreateHiring = () => {
        if (sourceLocation?.placeName === "" || destinationLocation?.placeName === "" || minPassenger === "" || maxPassenger === "" || theDescription === "" || startingDate === "" || endingDate === "") {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            let createData = {
                "passenger_count": {
                    "maximum": parseInt(maxPassenger),
                    "minimum": parseInt(minPassenger)
                },
                "source_location": sourceLocation,
                "destination_location": destinationLocation,
                "description": theDescription,
                "start_date": startingDate,
                "end_date": endingDate,
                "hiring_type": "Individual Hiring",
                "features": selectedFeatures,
                "return_type": activeTripType,
                "other_visits": []
            }
            setIsBusy(true)
            try {
                postApi(`${HIRING_URL}${RouteConstants.CREATE_HIRING_REQUEST}`, createData)
                    .then((response: any) => {
                        console.log(response)
                        notification.success({ message: response?.data?.message })
                        handleReFetch();
                        handleClose()
                    })
            } catch (error: any) {
                console.log(error)
                notification.error({ message: error?.response?.data?.message })
                setIsBusy(false)
            }
        }
    }

    return (
        <>
            <Modal title="Create Hiring Request" open={isOpened} onCancel={handleClose} maskClosable={false} keyboard={false} footer={null}>
                <Spin spinning={isBusy}>
                    <hr className='my-3' />
                    {/* content */}
                    <div className='mt-3'>
                        <div>
                            <LoadingPointSelector onChange={(data) => handleSourceSelect(data)} placeholder='Search pick up location' />
                        </div>

                        <div className='mt-3'>
                            <LoadingPointSelector onChange={(data) => handleDestinationSelect(data)} placeholder='Search destination location' />
                        </div>

                        <div className='mt-3'>
                            <p className='text-md'>Click to select trip type</p>

                            <div className='mt-1 flex gap-5'>
                                {
                                    ["One Way Trip", "Return Trip"].map((item, i) => (
                                        <div key={i} className={`${activeTripType === item ? 'bg-oya-ghana-red text-white' : 'bg-white'} px-3 py-1 rounded-[5px] shadow-sm cursor-pointer border-[1px] border-oya-ghana-red`} onClick={() => setActiveTripType(item)}>
                                            {item}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="mt-3 flex justify-between gap-3">
                            <input type='number' className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={minPassenger} onChange={(e) => setMinPassenger(e.target.value)} placeholder='Minimum Passengers' />

                            <input type='number' className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={maxPassenger} onChange={(e) => setMaxPassenger(e.target.value)} placeholder='Maximum Passengers' />
                        </div>

                        <div className='mt-3'>
                            <textarea className='w-full h-[80px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={theDescription} onChange={(e) => setTheDescription(e.target.value)} placeholder='Description' />
                        </div>

                        <div className="mt-3 flex justify-between gap-3">
                            <DateTimeInput onDateChange={handleStartDateChange} placeholder='Select start date/time' />

                            <DateTimeInput onDateChange={handleEndDateChange} placeholder='Select end date/time' />
                        </div>

                        <div className='mt-3'>
                            <BusFeaturesSelector onChange={(data) => setSelectedFeatures(data)} />
                        </div>

                        {/* footer */}
                        <div className='mt-5 flex gap-4 justify-end'>
                            <button className='text-gray-600 text-[14px]' onClick={handleClose}>Cancel</button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleCreateHiring()} disabled={isBusy}>
                                {
                                    isBusy ? <Spinner /> : "Approve"
                                }
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    );
}

export default CreateHiringModal;