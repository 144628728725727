import { useState } from 'react';
import { Modal, notification } from 'antd';
import { HiringData } from '../payload.model';
import { HIRING_URL } from '../../../helpers/constants';
import { putApi } from '../../../utils/api';
import { Spinner } from '@material-tailwind/react';
import { getCookie } from '../../../helpers/utils';
import { RouteConstants } from '../../../helpers/RouteConstants';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    hiringData: HiringData
}

const AssignPaymentModal = ({ isOpened, handleClose, handleReFetch, hiringData }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const userId = getCookie('oya_usr')
    const [theAmount, setTheAmount] = useState("")
    const [theReference, setTheReference] = useState("")



    //handleAssign
    const handleAssign = () => {
        if (!theAmount) {
            notification.warning({ message: "Please enter an amount" })
        }
        else {
            let data = {
                "country_code": "GH",
                "id": parseInt(userId),
                "hiring_id": hiringData?.id,
                "amount": parseInt(theAmount),
                "reference": theReference
            }
            setIsBusy(true)

            putApi(`${HIRING_URL}${RouteConstants.ASSIGN_PRICE}`, data)
                .then((response: any) => {
                    // console.log(response)
                    if (response?.status === 200 || response?.status === 201) {
                        notification.success({ message: response?.data?.message })
                        setIsBusy(false)
                        handleClose()
                        handleReFetch()
                    } else {
                        notification.error({ message: response?.response?.data?.message })
                        setIsBusy(false)
                    }
                })
                .catch((error: any) => {
                    // console.log(error)
                    setIsBusy(false)
                    notification.error({ message: error?.response?.data?.message })
                })
        }
    }

    return (
        <>
            <Modal title="Assign Price" open={isOpened} onCancel={handleClose} maskClosable={false} keyboard={false} footer={null}>
                <hr className='my-3' />
                <div className='mt-3'>

                    <div>
                        <input type='number' className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={theAmount} onChange={(e) => setTheAmount(e.target.value)} placeholder='Enter amount' />
                    </div>

                    <div className='mt-3'>
                        <textarea className='w-full h-[100px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={theReference} onChange={(e) => setTheReference(e.target.value)} placeholder='Enter reference' />
                    </div>

                    <div className='mt-5 flex gap-4 justify-end'>
                        <button className='text-gray-600 text-[14px]' onClick={handleClose}>Cancel</button>
                        <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleAssign()} disabled={isBusy}>
                            {
                                isBusy ? <Spinner /> : "Assign Price"
                            }
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AssignPaymentModal;