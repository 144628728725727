import { UserProvider } from "./context/userContext";
import "./css/App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ProtectedRoutes from "./helpers/router";
import Dashboard from "./pages/dashboard/dashboard";
import HiringRequestsPage from "./pages/hiringRequests/hiringRequests";
import HiringDetails from "./pages/hiringRequests/hiringDetails";
import BusFeaturesPage from "./pages/vehicleFeatures/busFeatures";
import UnAuthorizedAccess from "./pages/unauthorized/unAuthorizedAccess";
import FuelTypesPage from "./pages/fuelTypes/fuelTypes";
import VehicleBrandPage from "./pages/vehicleBrands/vehicleBrands";
import VehicleTypesPage from "./pages/vehicleTypes/vehicleTypes";
import VehicleTransmissionsPage from "./pages/vehicleTransmissions/vehicleTransmissions";
import VehicleStatusesPage from "./pages/vehicleStatuses/vehicleStatuses";
import VehicleModelsPage from "./pages/vehicleModels/vehicleModels";
import VehiclesPage from "./pages/vehicles/vehicles";

function App() {
  return (
    <>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="*" element={<h4>Page not found!</h4>} />

            <Route
              path="/unauthorized"
              element={
                <ProtectedRoutes>
                  <UnAuthorizedAccess />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/"
              element={
                <ProtectedRoutes>
                  <Dashboard />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/hiring-requests"
              element={
                <ProtectedRoutes>
                  <HiringRequestsPage />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/hiring-details/:id"
              element={
                <ProtectedRoutes>
                  <HiringDetails />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/vehicle-features"
              element={
                <ProtectedRoutes>
                  <BusFeaturesPage />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/vehicles"
              element={
                <ProtectedRoutes>
                  <VehiclesPage />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/vehicle-bookings"
              element={
                <ProtectedRoutes>
                  <HiringRequestsPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/fuel-types"
              element={
                <ProtectedRoutes>
                  <FuelTypesPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/vehicle-brands"
              element={
                <ProtectedRoutes>
                  <VehicleBrandPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/vehicle-types"
              element={
                <ProtectedRoutes>
                  <VehicleTypesPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/vehicle-transmissions"
              element={
                <ProtectedRoutes>
                  <VehicleTransmissionsPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/vehicle-statuses"
              element={
                <ProtectedRoutes>
                  <VehicleStatusesPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/vehicle-models"
              element={
                <ProtectedRoutes>
                  <VehicleModelsPage />
                </ProtectedRoutes>
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </>
  );
}

export default App;
