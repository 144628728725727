import { useState } from 'react';
import { Modal, notification } from 'antd';
import { HiringData } from '../payload.model';
import { HIRING_URL } from '../../../helpers/constants';
import { putApi } from '../../../utils/api';
import { Spinner } from '@material-tailwind/react';
import { RouteConstants } from '../../../helpers/RouteConstants';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    hiringData: HiringData
}

const MarkAsPaid = ({ isOpened, handleClose, handleReFetch, hiringData }: modalProps) => {

    const [theReference, setTheReference] = useState("")
    const [isBusy, setIsBusy] = useState(false)

    //handleApprove
    const handleMarkAsPaid = async () => {
        let data = {
            "hiring_id": hiringData?.id,
            "reference": theReference
        }
        console.log(data)
        setIsBusy(true)

        try {
            const response: any = await putApi(`${HIRING_URL}${RouteConstants.MARK_AS_PAID}`, data)
            console.log(response)
            if (response?.status === 200 || response?.status === 201) {
                notification.success({ message: response?.data?.message })
                handleClose()
                handleReFetch()
            }
            else {
                setIsBusy(false)
                notification.error({ message: response?.response?.data?.message })
            }
        } catch (error: any) {
            console.log(error)
            setIsBusy(false)
            notification.error({ message: error?.response?.data?.message })
        }


    }

    return (
        <>
            <Modal title="Mark As Paid" open={isOpened} onCancel={handleClose} maskClosable={false} keyboard={false} footer={null}>
                <hr className='my-3' />
                <div className='mt-3'>

                    <div>
                        <textarea className='w-full h-[100px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={theReference} onChange={(e) => setTheReference(e.target.value)} placeholder='Enter reference' />
                    </div>

                    <div className='mt-5 flex gap-4 justify-end'>
                        <button className='text-gray-600 text-[14px]' onClick={handleClose}>Cancel</button>
                        <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleMarkAsPaid()} disabled={isBusy}>
                            {
                                isBusy ? <Spinner /> : "Approve"
                            }
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default MarkAsPaid;