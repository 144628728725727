import { useState, useEffect, useMemo } from "react";
import { getApi } from "../../utils/api";
import { HIRING_URL } from "../../helpers/constants";
import { RouteConstants } from "../../helpers/RouteConstants";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import moment from "moment";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { HiringData } from "../hirings/payload.model";
import { Typography } from "@material-tailwind/react";
import StatusSelector from "../../components/general/statusSelector";
import { LaunchOutlined, ArrowDropDownOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space, type MenuProps } from "antd";
import ApproveHiringRequest from "../hirings/modals/approveHiringRequest";
import MarkAsPaid from "../hirings/modals/markAsPaid";
import AssignDriverModal from "../hirings/modals/assignDriverModal";
import AssignBusModal from "../hirings/modals/assignBusModal";
import AssignPaymentModal from "../hirings/modals/assignPaymentModal";
import CreateHiringModal from "../hirings/modals/createHiringModal";

const HiringRequestsPage = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [allHiringRequests, setAllHiringRequets] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [savedData, setSavedData] = useState<any>(null);
  const [reFetch, setReFetch] = useState(false);

  //open create modal
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = () => {
    setIsCreateOpen(true);
  };
  const handleCreateClose = () => {
    setIsCreateOpen(false);
  };

  //open approve modal
  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const handleApproveOpen = () => {
    setIsApproveOpen(true);
  };
  const handleApproveClose = () => {
    setIsApproveOpen(false);
  };

  //open mark as paid modal
  const [isMarkAsPaid, setIsMarkAsPaid] = useState(false);
  const handleOpenMark = () => {
    setIsMarkAsPaid(true);
  };
  const handleCloseMark = () => {
    setIsMarkAsPaid(false);
  };

  //open driver modal
  const [openDriver, setOpenDriver] = useState(false);
  const handleOpenDriver = () => {
    setOpenDriver(true);
  };
  const handleCloseDriver = () => {
    setOpenDriver(false);
  };

  //open bus modal
  const [openBus, setOpenBus] = useState(false);
  const handleOpenBus = () => {
    setOpenBus(true);
  };
  const handleCloseBus = () => {
    setOpenBus(false);
  };

  //open pay modal
  const [openPayment, setOpenPayment] = useState(false);
  const handleOpenPay = () => {
    setOpenPayment(true);
  };
  const handleClosePay = () => {
    setOpenPayment(false);
  };

  //on page load
  useEffect(() => {
    const fetchHiringRequests = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (selectedStatus) {
          defaultFilters.push({
            f: "current_status.name",
            o: "=",
            p: [selectedStatus],
          });
        }

        const response = await getApi(
          `${HIRING_URL}${RouteConstants.ALL_HIRING_REQUESTS}?limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        // console.log(response?.data?.payload);
        setAllHiringRequets(response?.data?.payload?.items);
        setTotal(response?.data?.payload?.total);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHiringRequests();
  }, [pagination.pageIndex, pagination.pageSize, selectedStatus, reFetch]);

  const items: MenuProps["items"] = [
    {
      key: "1",

      label: (
        <button
          className="w-full text-left"
          onClick={() => handleApproveOpen()}
        >
          Approve Request
        </button>
      ),
    },
    {
      key: "2",

      label: (
        <button className="w-full text-left" onClick={() => handleOpenMark()}>
          Mark As Paid
        </button>
      ),
    },
    {
      key: "3",

      label: (
        <button className="w-full text-left" onClick={() => handleOpenDriver()}>
          Assign Driver
        </button>
      ),
    },
    {
      key: "4",

      label: (
        <button className="w-full text-left" onClick={() => handleOpenBus()}>
          Assign Bus
        </button>
      ),
    },
    {
      key: "5",

      label: (
        <button className="w-full text-left" onClick={() => handleOpenPay()}>
          Assign Price
        </button>
      ),
    },
  ];

  // table columns
  const columns = useMemo<MRT_ColumnDef<HiringData>[]>(
    () => [
      {
        id: "icons",
        header: "",
        accessorFn: (row: HiringData) => (
          <button
            title="View Details"
            onClick={() => navigate(`/hiring-details/${row?.id}`)}
          >
            <LaunchOutlined
              sx={{
                width: 20,
                height: 20,
                color: "#7C0000",
              }}
            />
          </button>
        ),
        size: 5,
      },
      {
        accessorFn: (row: HiringData) => row?.id,
        id: "id",
        header: "ID",
        size: 5,
      },
      {
        accessorFn: (row: HiringData) => row?.source?.place_name,
        id: "soure",
        header: "Pick Up",
      },
      {
        accessorFn: (row: HiringData) => `${row?.start_date}`,
        id: "start",
        header: "Start Date",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("LLL")}</Box>
        ),
      },
      {
        accessorFn: (row: HiringData) => row?.destination?.place_name,
        id: "dest",
        header: "Destination",
      },
      {
        accessorFn: (row: HiringData) => `${row?.end_date}`,
        id: "end",

        header: "End Date",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("LLL")}</Box>
        ),
      },
      {
        accessorFn: (row: HiringData) => (
          <div className="px-3 py-1 w-fit text-center rounded-[40px] bg-yellow-800 text-yellow-800 bg-opacity-[10%]">
            {row?.current_status?.name}
          </div>
        ),
        id: "stats",
        header: "Status",
      },
      {
        accessorFn: (row: HiringData) => `${row?.updated_at}`,
        id: "date",
        header: "Date Created",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("LLL")}</Box>
        ),
      },
      {
        id: "more",
        header: "Action",
        accessorFn: (row) => (
          <Box>
            <Dropdown
              trigger={["click"]}
              menu={{
                items,
              }}
              placement="bottomRight"
            >
              <button
                onClick={() => {
                  setSavedData(row);
                }}
              >
                <Space>
                  More
                  <ArrowDropDownOutlined />
                </Space>
              </button>
            </Dropdown>
          </Box>
        ),
        size: 5,
      },
    ],

    // eslint-disable-next-line
    []
  );

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  const handleStatusChange = (value: string) => {
    console.log(value);
    setSelectedStatus(value);
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar vehicleBookingsActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            {/* page content */}
            <div className="p-[10px] lg:p-[20px] h-full overflow-y-auto bg-[#7c000008]">
              <div>
                <Typography className="font-semibold text-xl">
                  Hiring Requests
                </Typography>
              </div>
              <div className="py-3 px-5 mt-3 rounded-[10px] bg-white shadow-md w-full flex justify-between items-center">
                <div className="w-[250px]">
                  <small>Filter by status</small>
                  <StatusSelector
                    onChange={(val) => handleStatusChange(val)}
                    allowClear={true}
                  />
                </div>
                <div>
                  <button
                    className="px-3 py-1 bg-oya-ghana-red text-white rounded-[5px]"
                    onClick={() => handleCreateOpen()}
                  >
                    + Create New
                  </button>
                </div>
              </div>

              <div className="mt-4 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={allHiringRequests}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{ pagination, isLoading: isFetching }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* open create modal */}
      {isCreateOpen ? (
        <CreateHiringModal
          isOpened={isCreateOpen}
          handleClose={handleCreateClose}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
      {/* open approve modal */}
      {isApproveOpen ? (
        <ApproveHiringRequest
          isOpened={isApproveOpen}
          handleClose={handleApproveClose}
          handleReFetch={handleReFetch}
          hiringData={savedData}
        />
      ) : (
        <></>
      )}
      {/* open mark as paid modal */}
      {isMarkAsPaid ? (
        <MarkAsPaid
          isOpened={isMarkAsPaid}
          handleClose={handleCloseMark}
          handleReFetch={handleReFetch}
          hiringData={savedData}
        />
      ) : (
        <></>
      )}
      {/* open assign driver */}
      {openDriver ? (
        <AssignDriverModal
          isOpened={openDriver}
          handleClose={handleCloseDriver}
          handleReFetch={handleReFetch}
          hiringData={savedData}
        />
      ) : (
        <></>
      )}
      {/* open assign bus */}
      {openBus ? (
        <AssignBusModal
          isOpened={openBus}
          handleClose={handleCloseBus}
          handleReFetch={handleReFetch}
          hiringData={savedData}
        />
      ) : (
        <></>
      )}
      {/* open assign payment */}
      {openPayment ? (
        <AssignPaymentModal
          isOpened={openPayment}
          handleClose={handleClosePay}
          handleReFetch={handleReFetch}
          hiringData={savedData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default HiringRequestsPage;
