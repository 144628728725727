import { useState } from 'react';
import { Modal, notification } from 'antd';
import { HiringData } from '../payload.model';
import { HIRING_URL } from '../../../helpers/constants';
import { RouteConstants } from '../../../helpers/RouteConstants';
import { putApi } from '../../../utils/api';
import DecisionSelector from '../../../components/general/decisionSelector';
import { Spinner } from '@material-tailwind/react';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    hiringData: HiringData
}

const ApproveHiringRequest = ({ isOpened, handleClose, handleReFetch, hiringData }: modalProps) => {

    const [selectedDecision, setSelectedDecision] = useState("")
    const [isBusy, setIsBusy] = useState(false)

    //handleApprove
    const handleApprove = async () => {
        if (!selectedDecision) {
            notification.warning({ message: "Please select a decision to proceed" })
        }
        else {
            let data = {
                "hiring_id": hiringData?.id,
                "decision": selectedDecision
            }
            setIsBusy(true)

            try {
                const response: any = await putApi(`${HIRING_URL}${RouteConstants.APPROVE_HIRING_REQUEST}`, data)
                notification.success({ message: response?.data?.message })
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }

        }
    }

    return (
        <>
            <Modal title="Approve Hiring Request" open={isOpened} onCancel={handleClose} maskClosable={false} keyboard={false} footer={null}>
                <hr className='my-3' />
                <div className='mt-3'>
                    <DecisionSelector onChange={(selected) => setSelectedDecision(selected)} isBusy={isBusy} />

                    <div className='mt-5 flex gap-4 justify-end'>
                        <button className='text-gray-600 text-[14px]' onClick={handleClose}>Cancel</button>
                        <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleApprove()} disabled={isBusy}>
                            {
                                isBusy ? <Spinner /> : "Approve"
                            }
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ApproveHiringRequest;