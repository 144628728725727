import { useState, useEffect } from "react";
import Select from "react-select";

interface searchProps {
  onChange: (data: any) => void;
  isBusy?: boolean;
}

const BusFeaturesSelector = ({ onChange, isBusy }: searchProps) => {
  const [isFetching, setIsFetching] = useState(false);

  //onLoad
  useEffect(() => {
    setIsFetching(true);
  }, []);

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select vehicle features"
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        options={[]}
        isLoading={isFetching}
        isDisabled={isFetching || isBusy}
        onChange={(newValue: any) => onChange(newValue)}
      />
    </>
  );
};

export default BusFeaturesSelector;
