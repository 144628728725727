import { notification } from "antd";
import { RouteConstants } from "../helpers/RouteConstants";
import { base_ride_ms_requests } from "../helpers/base_api";

export const getBranchRoles = async () => {
  try {
    const response = await base_ride_ms_requests.get(RouteConstants.ALL_ROLES);
    return response?.data?.payload?.items;
  } catch (error) {
    console.log(error);
    notification.error({ message: "Error while fetching branch roles" });
  }
};
