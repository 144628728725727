import { useState } from "react";
import { Input, Modal, Spin, notification } from "antd";
import { Spinner } from "@material-tailwind/react";
import { postApi } from "../../utils/api";
import { HIRING_URL } from "../../helpers/constants";
import { RouteConstants } from "../../helpers/RouteConstants";

interface modalProps {
  isOpened: boolean;
  handleClose: () => void;
  handleReFetch: () => void;
}

const CreateVehicleBrands = ({
  isOpened,
  handleClose,
  handleReFetch,
}: modalProps) => {
  const [isBusy, setIsBusy] = useState(false);
  const [vehicleBrandName, setVehicleBrandName] = useState("");
  const [vehicleBrandCode, setVehicleBrandCode] = useState("");

  //handleCreateHiring
  const handleCreateHiring = async () => {
    if (!vehicleBrandName || !vehicleBrandCode) {
      notification.warning({
        message: "Please enter check your form and enter the missing fields",
      });
    } else {
      let createData = {
        name: vehicleBrandName,
        code: vehicleBrandCode,
      };
      setIsBusy(true);
      try {
        await postApi(
          `${HIRING_URL}${RouteConstants.VEHICLE_BRANDS}`,
          createData
        );
        notification.success({
          message: `${vehicleBrandName} has been added successfully`,
        });
        handleReFetch();
        handleClose();
      } catch (error: any) {
        console.log(error);
        notification.error({ message: error?.response?.data?.message });
        setIsBusy(false);
      }
    }
  };

  return (
    <>
      <Modal
        title="Create Vehicle Brand"
        open={isOpened}
        onCancel={handleClose}
        maskClosable={false}
        keyboard={false}
        footer={null}
      >
        <Spin spinning={isBusy}>
          <hr className="my-3" />
          {/* content */}
          <div className="mt-3">
            <div className="w-full">
              <small>Enter Name</small>
              <Input
                placeholder="Opel"
                value={vehicleBrandName}
                onChange={(e) => setVehicleBrandName(e.target.value)}
              />
            </div>

            <div className="w-full">
              <small>Enter Code</small>
              <Input
                placeholder="OPEL"
                value={vehicleBrandCode}
                onChange={(e) => setVehicleBrandCode(e.target.value)}
              />
            </div>

            {/* footer */}
            <div className="mt-5 flex gap-4 justify-end">
              <button
                className="text-gray-600 text-[14px]"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400"
                onClick={() => handleCreateHiring()}
                disabled={isBusy}
              >
                {isBusy ? <Spinner /> : "Create"}
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateVehicleBrands;
