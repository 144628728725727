import { useState } from 'react';
import { Modal, notification } from 'antd';
import { HiringData } from '../payload.model';
import { HIRING_URL } from '../../../helpers/constants';
import { getApi, putApi } from '../../../utils/api';
import { Spinner } from '@material-tailwind/react';
import PhoneInput from "react-phone-number-input";
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { RouteConstants } from '../../../helpers/RouteConstants';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    hiringData: HiringData
}

const AssignDriverModal = ({ isOpened, handleClose, handleReFetch, hiringData }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("");
    const [foundDriver, setFoundDriver] = useState<any>(null)
    const [selectedDriver, setSelectedDriver] = useState<any>(null)
    const [findingDriver, setFindingDriver] = useState(false)


    //handlePhoneSearch
    const handlePhoneSearch = () => {
        setFindingDriver(true);
        const defaultFilters = [];
        if (phoneNumber) {
            defaultFilters.push({
                f: 'driver.phone',
                o: 'contains',
                p: [encodeURIComponent(phoneNumber)],
            })
        }

        getApi(`${HIRING_URL}${RouteConstants.DRIVERS_ROUTE}?filters=${JSON.stringify(defaultFilters)}`)
            .then((response: any) => {
                console.log(response?.data?.payload?.items)
                if (response?.data?.payload?.items.length === 0) {
                    notification.error({ message: "Driver was not found" })
                    setFindingDriver(false)
                }
                else {
                    setFoundDriver(response?.data?.payload?.items?.[0]?.driver?.name);
                    setSelectedDriver(response?.data?.payload?.items?.[0]?.driver?.id);
                    setFindingDriver(false);
                }
            })
            .catch((error: any) => {
                notification.error({ message: error?.response?.data?.message })
                console.log(error);
                setFindingDriver(false);
            })
    };

    //handleAssign
    const handleAssign = async () => {
        if (selectedDriver === null) {
            notification.warning({ message: "Please enter a valid driver's number" })
        }
        else {
            let data = {
                "hiring_id": hiringData?.id,
                "phone": phoneNumber,
                "driver_id": selectedDriver
            }
            setIsBusy(true)
            try {
                const response: any = await putApi(`${HIRING_URL}${RouteConstants.ASSIGN_DRIVER}`, data)
                console.log(response)
                if (response?.status === 200) {
                    notification.success({ message: response?.data?.message })
                    setIsBusy(false)
                    handleClose()
                    handleReFetch()
                } else {
                    notification.error({ message: "User not found" })
                }
            } catch (error: any) {
                // console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Assign A Driver" open={isOpened} onCancel={handleClose} maskClosable={false} keyboard={false} footer={null}>
                <hr className='my-3' />
                <div className='mt-3'>

                    <div>
                        {
                            foundDriver === null ?
                                <p className='text-md mb-1'>Input driver's phone number and click the search button</p>
                                :
                                <p className='font-semibold text-lg'>{foundDriver}</p>
                        }
                        <div className='flex gap-3 items-center'>
                            <PhoneInput
                                id="phone"
                                className="rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]"
                                placeholder="Driver's phone number"
                                defaultCountry="GH"
                                inputStyle={{ width: "100%" }}
                                numberInputProps={{
                                    className: "focus:outline-none"
                                }}
                                value={phoneNumber}
                                onChange={(phone: any) => setPhoneNumber(phone)}
                                disabled={isBusy}
                            />
                            {
                                findingDriver ? <Spinner /> : <button className='h-10 w-[50px] bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handlePhoneSearch()} disabled={!phoneNumber}>
                                    <MagnifyingGlassIcon className='h-4 w-4' />
                                </button>
                            }
                        </div>
                    </div>

                    <div className='mt-5 flex justify-between'>
                        <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleAssign()} disabled>
                            Create Driver
                        </button>
                        <div className='flex gap-4'>
                            <button className='text-gray-600 text-[14px]' onClick={handleClose}>Cancel</button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleAssign()} disabled={isBusy || foundDriver === null}>
                                {
                                    isBusy ? <Spinner /> : "Assign Driver"
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AssignDriverModal;