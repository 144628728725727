import { useState } from 'react';
import { Modal, notification } from 'antd';
import { HiringData } from '../payload.model';
import { Spinner } from '@material-tailwind/react';
import { putApi } from '../../../utils/api';
import { HIRING_URL } from '../../../helpers/constants';
import { RouteConstants } from '../../../helpers/RouteConstants';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    hiringData: HiringData
}

const AssignBusModal = ({ isOpened, handleClose, handleReFetch, hiringData }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theModel, setTheModel] = useState("")
    const [busReg, setBusReg] = useState("")
    const [maxNum, setMaxNum] = useState("")
    const [seatNum, setSeatNum] = useState("")

    //handleAssign
    const handleAssign = async () => {
        if (!theModel || !busReg || !maxNum || !seatNum) {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            setIsBusy(true)
            let data = {
                "model": theModel,
                "registeration": busReg,
                "max_number": parseInt(maxNum),
                "hiring_id": hiringData?.id,
                "seat_number": parseInt(seatNum)
            }
            putApi(`${HIRING_URL}${RouteConstants.ASSIGN_BUS}`, data)
                .then((response: any) => {
                    // console.log(response)
                    notification.success({ message: response?.data?.message })
                    setIsBusy(false)
                    handleClose()
                    handleReFetch()
                }).catch((error: any) => {
                    // console.log(error)
                    setIsBusy(false)
                    notification.error({ message: error?.response?.data?.message })
                })
        }
    }

    return (
        <>
            <Modal title="Assign A Bus" open={isOpened} onCancel={handleClose} maskClosable={false} keyboard={false} footer={null}>
                <hr className='my-3' />
                <div className='mt-3'>

                    <div>
                        <input className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={theModel} onChange={(e) => setTheModel(e.target.value)} placeholder='Bus Model' />
                    </div>

                    <div className='mt-3'>
                        <input className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={busReg} onChange={(e) => setBusReg(e.target.value)} placeholder='Registration No.' />
                    </div>

                    <div className='mt-3'>
                        <input type='number' className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={maxNum} onChange={(e) => setMaxNum(e.target.value)} placeholder='Max passengers' />
                    </div>

                    <div className='mt-3'>
                        <input type='number' className='w-full h-[40px] p-3 rounded-[5px] border-[1px] border-gray-600 focus:outline-blue-500' value={seatNum} onChange={(e) => setSeatNum(e.target.value)} placeholder='Bus capacity' />
                    </div>

                    <div className='mt-5 flex gap-4 justify-end'>
                        <button className='text-gray-600 text-[14px]' onClick={handleClose}>Cancel</button>
                        <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400' onClick={() => handleAssign()} disabled={isBusy}>
                            {
                                isBusy ? <Spinner /> : "Assign Bus"
                            }
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AssignBusModal;