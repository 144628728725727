import axios from "axios";
import { RIDE_MS_URL } from "./constants";
import { getCookie } from "./utils";

const token = getCookie("oya_token");

export const base_ride_ms_requests = axios.create({
  baseURL: `${RIDE_MS_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});
