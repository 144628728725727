import { useState, useEffect } from 'react'
import { getApi } from '../../utils/api';
import { RouteConstants } from '../../helpers/RouteConstants';
import Select from "react-select";
import { HIRING_URL } from '../../helpers/constants';

interface searchProps {
    onChange: (data: any) => void
    isBusy?: boolean
    placeholder: string
}

const LoadingPointSelector = ({ onChange, isBusy, placeholder }: searchProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allLoadingPoints, setAllLoadingPoints] = useState([])

    //onLoad
    useEffect(() => {
        const getLoadingPoints = async () => {
            setIsFetching(true)
            try {
                const response = await getApi(`${HIRING_URL}${RouteConstants.ALL_LOADING_POINTS}?limit=1000`)
                // console.log(response?.data)
                const rawData = response?.data?.payload
                const arranged = rawData.map((item: any) => (
                    { label: item?.location?.place_name, value: item?.location }
                ))
                setAllLoadingPoints(arranged)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getLoadingPoints()
    }, [])


    return (
        <>
            <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder={placeholder}
                isClearable={true}
                isSearchable={true}
                options={allLoadingPoints}
                isLoading={isFetching}
                isDisabled={isFetching || isBusy}
                onChange={(newValue: any) => onChange(newValue)}
            />
        </>
    )
}

export default LoadingPointSelector
