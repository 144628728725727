import Select from "react-select";
import { useState, useEffect } from "react";
import { RouteConstants } from "../../helpers/RouteConstants";
import { HIRING_URL } from "../../helpers/constants";
import { getApi } from "../../utils/api";

interface chooseBrandProp {
  selectedBrand: any;
  onBrandChange: (value: any) => void;
}

const BrandSelector = ({ selectedBrand, onBrandChange }: chooseBrandProp) => {
  const [isFetching, setIsFetching] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [brandNameInput, setBrandInput] = useState<any>("");

  const handleBrandChange = (value: any) => {
    onBrandChange(value || {});
  };

  const handleInputChange = (e: any) => {
    setBrandInput(e);
  };
  const fetchBrands = async () => {
    interface Filter {
      f: string;
      o: string;
      p: string[];
    }

    let defaultFilters: Filter[] = [];

    // Default filters
    defaultFilters.push({
      f: "name",
      o: "contains",
      p: [brandNameInput],
    });

    setIsFetching(true);

    try {
      const response = await getApi(
        `${HIRING_URL}${
          RouteConstants.VEHICLE_BRANDS
        }?limit=500&filters=${JSON.stringify(defaultFilters)}`
      );
      const mappedBrands = response?.data?.payload?.items.map(
        (brand: any, i: number) => ({
          value: brand?.code,
          label: brand?.name,
        })
      );
      setBrandsList(mappedBrands);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandNameInput]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      placeholder="Select a brand"
      isSearchable={true}
      name="brands"
      options={brandsList}
      isLoading={isFetching}
      value={selectedBrand}
      onChange={handleBrandChange}
      onInputChange={handleInputChange}
      isClearable
    />
  );
};

export default BrandSelector;
