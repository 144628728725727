import { Typography } from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../../components/layouts/navbar";
import CustomSideBar from "../../components/layouts/sidebar";
import { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { HIRING_URL } from "../../helpers/constants";
import { HiringData } from "../hirings/payload.model";
import moment from "moment";
import { Spin, Steps } from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { MapPinIcon, StopIcon } from "@heroicons/react/24/solid";
import { RouteConstants } from "../../helpers/RouteConstants";

const HiringDetails = () => {
  const hiringId = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [singleHiring, setSingleHiring] = useState<HiringData>();

  //on page load
  useEffect(() => {
    const fetchHiringRequests = async () => {
      setIsFetching(true);
      try {
        const response = await getApi(
          `${HIRING_URL}${RouteConstants.ALL_HIRING_REQUESTS}/${hiringId?.id}`
        );
        // console.log(response?.data?.payload);
        setSingleHiring(response?.data?.payload);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHiringRequests();
  }, [hiringId]);

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar vehicleBookingsActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            {/* page content */}
            <div className="p-[10px] lg:p-[20px] h-full overflow-y-auto bg-[#7c000008]">
              <div className="flex items-center gap-3">
                <button
                  className="px-3 py-1 rounded-[5px] bg-oya-ghana-green text-white flex gap-2 items-center"
                  onClick={() => navigate("/hiring-requests")}
                >
                  <ArrowLeftIcon className="h-4 w-5" />
                  Back
                </button>
                <Typography className="font-semibold text-xl">
                  Hiring Request Details
                </Typography>
              </div>

              <div className="bg-white rounded-[10px] p-8 shadow-md mt-5 mb-20">
                <Spin spinning={isFetching} size="large">
                  <div className="mb-5">
                    <h2 className="text-xl font-semibold">
                      Request ID: {singleHiring?.id}
                    </h2>
                  </div>

                  <div className="my-5">
                    <Steps
                      current={1}
                      items={[
                        {
                          title: (
                            <p className="font-semibold">Pick Up Location</p>
                          ),
                          description: (
                            <p className="font-medium text-gray-600">
                              {singleHiring?.source?.place_name},{" "}
                              {singleHiring?.source?.region}
                            </p>
                          ),
                          icon: (
                            <StopIcon className="h-5 w-5 mt-2 text-oya-ghana-red" />
                          ),
                        },
                        {
                          title: (
                            <p className="font-semibold">Drop Off Location</p>
                          ),
                          description: (
                            <p className="font-medium text-gray-600">
                              {singleHiring?.destination?.place_name},{" "}
                              {singleHiring?.destination?.region}
                            </p>
                          ),
                          icon: (
                            <MapPinIcon className="h-5 w-5 mt-2 text-green-700" />
                          ),
                        },
                      ]}
                    />
                  </div>

                  <hr className="my-3" />

                  <div className="my-5 lg:grid lg:grid-cols-3 gap-x-[30px] w-full">
                    <div>
                      <h2 className="text-xl font-semibold">
                        Approval Status:
                      </h2>
                      {singleHiring?.approval_status === "Approved" ? (
                        <div className="px-3 py-1 mt-2 w-fit text-center rounded-[40px] bg-green-800 text-green-800 bg-opacity-[10%]">
                          {singleHiring?.approval_status}
                        </div>
                      ) : (
                        <div className="px-3 py-1 mt-2 w-fit text-center rounded-[40px] bg-yellow-800 text-yellow-800 bg-opacity-[10%]">
                          {singleHiring?.approval_status}
                        </div>
                      )}
                    </div>

                    <div>
                      <h2 className="text-xl font-semibold">Trip Type:</h2>
                      <p className="text-gray-600">
                        {singleHiring?.return_type}
                      </p>
                    </div>

                    <div>
                      <h2 className="text-xl font-semibold">Hiring Type:</h2>
                      <p className="text-gray-600">
                        {singleHiring?.hiring_type}
                      </p>
                    </div>
                  </div>

                  <hr className="my-3" />

                  <div className="my-5 lg:grid lg:grid-cols-3 gap-x-[30px] w-full">
                    <div>
                      <h2 className="text-xl font-semibold">Request Date:</h2>
                      <p className="text-gray-600">
                        {moment(singleHiring?.created_at).format(
                          "Do MMMM YYYY - hh:mm A"
                        )}
                      </p>
                    </div>
                    <div>
                      <h2 className="text-xl font-semibold">Start Date:</h2>
                      <p className="text-gray-600">
                        {moment(singleHiring?.start_date).format(
                          "Do MMMM YYYY - hh:mm A"
                        )}
                      </p>
                    </div>
                    <div>
                      <h2 className="text-xl font-semibold">End Date:</h2>
                      <p className="text-gray-600">
                        {moment(singleHiring?.end_date).format(
                          "Do MMMM YYYY - hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="my-3" />

                  <div className="my-5 lg:grid lg:grid-cols-3 gap-x-[30px] w-full">
                    <div className="mb-4">
                      <h2 className="text-xl font-semibold">
                        Passenger Information:
                      </h2>
                      <p className="text-gray-600">
                        Minimum Passengers:{" "}
                        {singleHiring?.passenger_summary_data.min_passengers}
                      </p>
                      <p className="text-gray-600">
                        Maximum Passengers:{" "}
                        {singleHiring?.passenger_summary_data.max_passengers}
                      </p>
                      <p className="text-gray-600">
                        Actual Passengers:{" "}
                        {singleHiring?.passenger_summary_data.actual_passengers}
                      </p>
                    </div>

                    <div className="mb-4">
                      <h2 className="text-xl font-semibold">Travel Creator:</h2>
                      <p className="text-gray-600">
                        Name: {singleHiring?.creator?.name}
                      </p>
                      <p className="text-gray-600">
                        Phone: {singleHiring?.creator?.phone}
                      </p>
                      <p className="text-gray-600">
                        ICE 1: {singleHiring?.creator?.ice1_phone}
                      </p>
                      <p className="text-gray-600">
                        ICE 2: {singleHiring?.creator?.ice2_phone}
                      </p>
                    </div>

                    <div className="mb-4">
                      <h2 className="text-xl font-semibold">Current Status:</h2>
                      <p className="text-gray-600">
                        Status: {singleHiring?.current_status?.name}
                      </p>
                      <p className="text-gray-600">
                        Date:{" "}
                        {moment(
                          singleHiring?.current_status?.created_at
                        ).format("Do MMMM YYYY - hh:mm A")}
                      </p>
                    </div>
                  </div>

                  <hr className="my-3" />

                  <div className="my-5">
                    <h2 className="text-xl font-semibold">Description:</h2>
                    <p className="text-gray-600">{singleHiring?.description}</p>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HiringDetails;
