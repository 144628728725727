import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { getApi } from '../../utils/api'
import { HIRING_URL } from '../../helpers/constants'
import { RouteConstants } from '../../helpers/RouteConstants'

interface selectorProps {
    allowClear?: boolean
    onChange?: (val: any) => void
    isBusy: boolean
}

const DecisionSelector = ({ allowClear, onChange, isBusy }: selectorProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allDecisions, setAllDecisions] = useState([])

    //onload
    useEffect(() => {
        const getAllDecisions = async () => {
            setIsFetching(true)
            try {
                const response = await getApi(
                    `${HIRING_URL}${RouteConstants.ALL_DECISIONS}?limit=1`
                );
                // console.log(response?.data)
                const temp = response?.data?.payload
                const arranged = temp.map((item: any) => (
                    { value: item?.name, label: item?.name }
                ))
                setAllDecisions(arranged)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllDecisions()
    }, [])

    return (
        <>
            <Select
                className='w-full'
                options={allDecisions}
                onChange={onChange}
                placeholder="Select a decision"
                loading={isFetching}
                disabled={isFetching || isBusy}
                allowClear={allowClear}
                listHeight={500}
            />
        </>
    )
}

export default DecisionSelector
