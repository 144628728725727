export const RouteConstants = {
  VEHICLES: "/v1/vehicles",
  ALL_HIRING_REQUESTS: "/v1/hiring-requests",
  VEHICLE_FEATURES: "/v1/vehicle-features",
  FUEL_TYPES: "/v1/fuel-types",
  VEHICLE_BRANDS: "/v1/vehicle-brands",
  VEHICLE_MODELS: "/v1/vehicle-models",
  VEHICLE_STATUSES: "/v1/vehicle-statuses",
  VEHICLE_TYPES: "/v1/vehicle-types",
  VEHICLE_TRANSMISSIONS: "/v1/vehicle-transmission",
  ALL_HIRING_STATUS: "/hiring_event/all",
  APPROVE_HIRING_REQUEST: "/hiring_request/approve",
  ALL_DECISIONS: "/approval_status/all",
  MARK_AS_PAID: "/hiring_request/mark-as-paid",
  APPROVE_PAYMENT: "/hiring_request/approve-payment",
  ASSIGN_DRIVER: "/hiring_request/assign-driver",
  ASSIGN_BUS: "/hiring_request/assign-bus",
  ASSIGN_PRICE: "/hiring_request/cash_payment",
  ALL_LOADING_POINTS: "/loading_point/all",
  DRIVERS_ROUTE: "/v2/drivers",
  CREATE_HIRING_REQUEST: "/hiring_request",
  ALL_ROLES: "/v2/me/hiring-manager-roles",
};
