import CustomNavbar from '../../components/layouts/navbar'
import CustomSideBar from '../../components/layouts/sidebar'
import underConstruction from "../../images/processing.gif"

const Dashboard = () => {
    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="flex h-full overflow-hidden">
                    {/* sidebar */}
                    <CustomSideBar dashActive="active" />
                    {/* navbar */}
                    <div className="w-full h-full">
                        <CustomNavbar />

                        {/* page content */}
                        <div className="p-[10px] lg:p-[20px] h-full overflow-auto">
                            <div className='flex justify-center items-center h-full'>
                                <img src={underConstruction} alt="load" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
