import { StatusData } from "../vehicleStatuses/payload.model";

interface statusProps {
  status: StatusData;
}

const StatusTag = ({ status }: statusProps) => {
  console.log(status);

  let bgColorClass;
  let textColorClass;

  if (status?.code && status.code === "PENDING") {
    bgColorClass = "bg-yellow-500";
  } else if (status?.code && status.code === "AVAILABLE") {
    bgColorClass = "bg-green-500";
  } else if (status?.code && status.code === "UNAVAILABLE") {
    bgColorClass = "bg-red-500";
  }

  if (status?.code && status.code === "PENDING") {
    textColorClass = "text-black";
  } else if (status?.code && status.code === "AVAILABLE") {
    textColorClass = "text-white";
  } else if (status?.code && status.code === "UNAVAILABLE") {
    textColorClass = "text-white";
  }

  return (
    <div
      className={`px-3 py-1 ${bgColorClass} ${textColorClass} rounded-[30px] flex justify-center items-center w-fit`}
    >
      <p className="text-xs lg:text-md">{status?.name ?? status?.name}</p>
    </div>
  );
};

export default StatusTag;
