import { Select } from "antd";
import { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { HIRING_URL } from "../../helpers/constants";
import { RouteConstants } from "../../helpers/RouteConstants";

interface selectorProps {
  allowClear?: boolean;
  onChange?: (val: any) => void;
}

const StatusSelector = ({ allowClear, onChange }: selectorProps) => {
  const [allStatus, setAllStatus] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  //onload
  useEffect(() => {
    const getAllStatus = async () => {
      setIsFetching(true);
      try {
        const response = await getApi(
          `${HIRING_URL}${RouteConstants.VEHICLE_STATUSES}`
        );
        const temp = response?.data?.payload?.items;
        const arranged = temp.map((item: any) => ({
          value: item?.code,
          label: item?.name,
        }));
        setAllStatus(arranged);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    getAllStatus();
  }, []);

  return (
    <>
      <Select
        className="w-full"
        options={allStatus}
        onChange={onChange}
        placeholder="Select..."
        loading={isFetching}
        disabled={isFetching}
        allowClear={allowClear}
        listHeight={500}
      />
    </>
  );
};

export default StatusSelector;
